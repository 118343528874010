@use 'sass:list';
@import '../../styles/theme';

$grid-background-color: #fff;
$timeline-events-no: 10;
$timeline-years-no: 10;
$timeline-events-line-height: 8px;
$timeline-colors: #d66000, #008b2e, #56c2f3, #f58f8b, #333333;

// Timeline - Events
.timelineEvents {
  padding: 0;
  list-style: none;
  white-space: nowrap;
  // Fix display: inline-block spacing issue
  font-size: 0;
  @media screen and (max-width: 910px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    & > li {
      margin-bottom: 5rem;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
.timelineEvents h2,
.timelineEvents h3,
.timelineEvents h4 {
  margin: 0;
  font-weight: 500;
  font-size: 0.8rem;
  line-height: 1rem;
}
.timelineEvents h2 {
  color: #777;
  text-transform: uppercase;
}
.timelineEvents h4 {
  color: #000;
  font-style: italic;
}

// Timeline - Positioning and horizontal line
.timelineEvents li {
  position: relative;
  display: inline-block;
}
.timelineEvents li:before {
  content: '';
  position: absolute;
  left: 0;
  bottom: -36px;
  height: $timeline-events-line-height;
  border-radius: $timeline-events-line-height;
}
@for $i from 1 through $timeline-events-no {
  .timelineEvents li:nth-child(#{$i}) {
    bottom: #{((($timeline-events-line-height * 2) * $i)) - ($timeline-events-line-height * 2)};
  }
}

// Timeline - Events - Colours
@for $i from 1 through list.length($timeline-colors) {
  .timelineEvents li:nth-child(#{$i}) {
    &::before {
      background: #{list.nth($timeline-colors, $i)};
    }
    & h3 {
      color: darken(list.nth($timeline-colors, $i), 20%);
    }
  }
}

.timelineEvents li:first-child::before {
  background-image: linear-gradient(to right, $grid-background-color 0, #{list.nth($timeline-colors, 1)} 100%);
  @media screen and (max-width: 910px) {
    background-image: none;
  }
}

.timelineEvents li:last-child:before {
  background-image: linear-gradient(to right, #{list.nth($timeline-colors, -1)} 0, $grid-background-color 100%);
  @media screen and (max-width: 910px) {
    background-image: none;
  }
}

// Timeline - Events - Grid
@for $i from 0 through $timeline-years-no {
  .timelineEventYears#{$i} {
    width: #{$i * 10%};
    @media screen and (max-width: 910px) {
      width: 70%;
    }
  }
  .timelineEventYears#{$i}:before {
    width: 100%;
  }
}
